<template>
  <div>
    <ListAndAddCard
      :show-add="$auth.userCan('CREATE_PAYMENT_METHODS') && false"
      path="payment-methods"
      :cols="[
        { text: 'Card', value: 'card' },
        { text: 'Default', value: 'default' },
        { text: 'Actions', value: 'actions' }
      ]"
      :request-params="requestParams"
      title="Payment Methods"
      icon="mdi-credit-card-outline"
    >
      <template v-slot:item.card="{ item: paymentMethod }">
        {{ paymentMethod.card_brand }} {{ paymentMethod.card_exp_last4 }}
      </template>
      <template v-slot:item.default="{ item: paymentMethod }">
        <v-icon
          v-if="paymentMethod.default"
          x-small
          dense
        >
          mdi-check-circle-outline
        </v-icon>
        <template v-else>-</template>
      </template>
      <template v-slot:item.actions="{ item: paymentMethod }">
        <v-btn
          v-user-can:UPDATE_PAYMENT_METHODS
          :loading="loading"
          depressed
          x-small
          color="primary"
          @click="makeDefaultPaymentMethod(paymentMethod)"
        >
          Make Default
        </v-btn>
        <v-btn
          v-user-can:DELETE_PAYMENT_METHODS
          color="error"
          text
          x-small
          @click="deletePaymentMethod(paymentMethod)"
        >
          Delete
        </v-btn>
      </template>
    </ListAndAddCard>

    <SnackBar success ref="successBar">
      Payment method updated successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue updating the payment method.
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import ListAndAddCard from '@/components/ListAndAddCard'
import HasRequestParams from '@/mixins/HasRequestParams'
import SnackBar from '@/components/SnackBar'

export default {
  name: 'PaymentMethodsList',

  mixins: [HasRequestParams],

  components: {
    ListAndAddCard,
    SnackBar
  },

  data () {
    return {
      loading: false
    }
  },

  methods: {
    makeDefaultPaymentMethod (paymentMethod) {
      this.loading = true
      this.$api.persist('put', {
        path: `payment-methods/${paymentMethod.id}`,
        object: {
          ...paymentMethod,
          ...{ default: true }
        }
      })
        .then(() => {
          this.$refs.successBar.open()
        })
        .catch(() => {
          this.$refs.errorBar.open()
        })
        .finally(() => { this.loading = false })
    },

    deletePaymentMethod (paymentMethod) {
      this.$api.delete({
        path: `payment-methods/${paymentMethod.id}`
      })
    }
  }
}
</script>
