<template>
  <v-container fluid>
    <InnerPageBackHeader @back="$router.go(-1)">
      <template v-slot:right>
        <p class="mb-0">
          <small><strong>Created At:</strong> {{ $momentDate(customer.created_at) }}</small>
          <small class="ml-2"><strong>Updated At:</strong> {{ $momentDateTimeHuman(customer.updated_at) }}</small>
          <v-btn
            class="ml-2"
            outlined
            dark
            small
            depressed
            color="primary"
            @click="getCustomer"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </p>
      </template>
    </InnerPageBackHeader>

    <v-row dense class="mt-4">
      <!-- LEFT COL -->
      <v-col
        cols="12"
        sm="4"
      >
        <v-card
          :loading="loading"
          outlined
        >
          <v-card-text>
            <CustomerSummary
              :customer="customer"
            />

            <BookingsList
              :request-params="{ customer_id: customerId }"
            />

            <PaymentMethodsList
              :request-params="{ customer_id: customerId }"
              class="mt-4"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <!-- MIDDLE COL -->
      <v-col
        cols="12"
        sm="5"
      >
        <InnerPageNavigation
          :tabs="[
            { disabled: !$auth.userCan('READ_CUSTOMERS'), icon: 'mdi-timeline-text', label: 'Customer Timeline', to: { name: 'customers.timeline', params: { customerId: this.customerId }} }
          ]"
        />
        <component
          v-if="customer.id"
          :is="$route.meta.innerComponent || 'CustomerTimeline'"
          :customer="customer"
        />
      </v-col>

      <!-- RIGHT COL -->
      <v-col
        cols="12"
        sm="3"
      >
        <v-card outlined>
          <v-card-text>
            <v-btn
              :href="customer.stripe_url"
              target="_blank"
              block
              small
            >
              View On Stripe
            </v-btn>
            <v-btn
              v-if="customer.accounting_contact_id"
              :href="customer.accounting_contact_url"
              target="_blank"
              class="mt-3"
              block
              small
            >
              View On Xero
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>
<script type="text/javascript">
import InnerPageBackHeader from '@/components/InnerPageBackHeader'
import CustomerSummary from '@/components/customers/CustomerSummary'
import BookingsList from '@/components/bookings/BookingsList'
import PaymentMethodsList from '@/components/payment-methods/PaymentMethodsList'
import CustomerTimeline from '@/components/customers/CustomerTimeline'
import InnerPageNavigation from '@/components/InnerPageNavigation'

export default {
  name: 'ShowCustomer',

  components: {
    InnerPageBackHeader,
    CustomerSummary,
    BookingsList,
    PaymentMethodsList,
    CustomerTimeline,
    InnerPageNavigation
  },

  props: {
    customerId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      loading: false,
      customer: {
        //
      }
    }
  },

  watch: {
    $route: function () {
      this.setup()
    }
  },

  mounted () {
    this.setup()
  },

  methods: {
    setup () {
      this.getCustomer()

      if (window.Echo) {
        window.Echo.private(`users.${this.customerId}`)
          .listen('UserUpdated', () => {
            this.getCustomer()
          })
      }
    },

    getCustomer () {
      this.loading = true
      this.$api.get({
        path: `customers/${this.customerId}`,
        params: {}
      })
        .then(({ data : customer }) => {
          this.customer = customer
        })
        .catch(() => {
          //
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>
