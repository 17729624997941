<template>
  <div>
    <div class="d-flex justify-around">
      <div class="d-block">
        <h2 class="text-left w-full">{{ customer.name }}</h2>
      </div>
      <div class="d-block">
        <v-btn
          v-user-can:UPDATE_USERS
          v-if="customer.id"
          small
          text
          color="primary"
          @click="editDialog = true"
        >
          Edit
        </v-btn>
      </div>
    </div>
    <p><small>{{ customer.id }}</small></p>

    <v-list-item
      class="mt-2 pl-0"
      three-line
    >
      <v-list-item-content>
        <v-list-item-subtitle class="pl-1 mt-1">
          <a
            :href="`mailto: ${customer.email}`"
            style="text-decoration: none;"
          >
            {{ customer.email }}
          </a>
        </v-list-item-subtitle>
        <v-list-item-subtitle class="pl-1 mt-1">
          <a
            :href="`circleloop: ${customer.mobile}`"
            style="text-decoration: none;"
          >
            {{ customer.mobile }}
          </a>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <CustomerEditDialog
      v-bind="{ customer }"
      :dialog.sync="editDialog"
    />
  </div>
</template>
<script type="text/javascript">
import HasCustomer from '@/mixins/HasCustomer'
import CustomerEditDialog from '@/components/customers/CustomerEditDialog'

export default {
  name: 'CustomerSummary',

  components: {
    CustomerEditDialog
  },

  mixins: [HasCustomer],

  data () {
    return {
      editDialog: false
    }
  }
}
</script>
