var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ListAndAddCard',{attrs:{"show-add":_vm.$auth.userCan('CREATE_BOOKINGS') && false,"cols":[
      { text: 'Job Numbers', value: 'job_numbers' },
      { text: 'Status', value: 'payment_status_label' },
      { text: 'Actions', value: 'actions' }
    ],"request-params":Object.assign({}, _vm.requestParams, { with: ['bikes'] }),"title":"Bookings","path":"bookings","icon":"mdi-bicycle"},scopedSlots:_vm._u([{key:"item.job_numbers",fn:function(ref){
    var item = ref.item;
return _vm._l((item.bikes),function(bike){return _c('v-chip',{key:bike.id,attrs:{"small":""}},[_vm._v(" "+_vm._s(bike.ref || ' - ')+" ")])})}},{key:"item.actions",fn:function(ref){
    var booking = ref.item;
return [_c('v-btn',{directives:[{name:"user-can",rawName:"v-user-can:READ_BOOKINGS",arg:"READ_BOOKINGS"}],attrs:{"to":{ name: 'bookings.show', params: { bookingId: booking.id } },"color":"primary","depressed":"","x-small":""}},[_vm._v(" View ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }