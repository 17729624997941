<template>
  <div>
    <ListAndAddCard
      :show-add="$auth.userCan('CREATE_BOOKINGS') && false"
      :cols="[
        { text: 'Job Numbers', value: 'job_numbers' },
        { text: 'Status', value: 'payment_status_label' },
        { text: 'Actions', value: 'actions' }
      ]"
      :request-params="{ ...requestParams, ...{ with: ['bikes'] } }"
      title="Bookings"
      path="bookings"
      icon="mdi-bicycle"
    >
      <template v-slot:item.job_numbers="{ item }">
        {{ item.job_numbers }}
      </template>
      <template v-slot:item.job_numbers="{ item }">
        <v-chip
          v-for="bike in item.bikes"
          :key="bike.id"
          small
        >
          {{ bike.ref || ' - ' }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item: booking }">
        <v-btn
          :to="{ name: 'bookings.show', params: { bookingId: booking.id } }"
          v-user-can:READ_BOOKINGS
          color="primary"
          depressed
          x-small
        >
          View
        </v-btn>
      </template>
    </ListAndAddCard>
  </div>
</template>
<script type="text/javascript">
import ListAndAddCard from '@/components/ListAndAddCard'
import HasRequestParams from '@/mixins/HasRequestParams.js'

export default {
  name: 'BookingsList',

  mixins: [HasRequestParams],

  components: {
    ListAndAddCard
  }
}
</script>
